export const MUI_X_PRO_LICENSE =
  '82281641f885336d8c6b61faa73edecdT1JERVI6c3R1ZGlvXzEyMyxFWFBJUlk9MTY3NzY2NTQ3MjM4MSxLRVlWRVJTSU9OPTE=';
export const HTML_ID_EDITOR_OVERLAY = 'editor-overlay';
export const WINDOW_PROP_TOOLPAD_APP_RENDER_PARAMS = '__TOOLPAD_APP_RENDER_PARAMS__';
export const RUNTIME_CONFIG_WINDOW_PROPERTY = '__TOOLPAD_RUNTIME_CONFIG__';

export const TOOLPAD_TARGET_CE = 'CE';
export const TOOLPAD_TARGET_CLOUD = 'CLOUD';
export const TOOLPAD_TARGET_PRO = 'PRO';
export const REPOSITORY_URL = 'https://github.com/mui/mui-toolpad-0-0';
export const LANDING_PAGE_URL = 'https://mui.com/toolpad/';
export const DOCUMENTATION_URL = 'https://mui.com/toolpad/getting-started/overview/';
export const TOOLPAD_LOCAL_ANNOUNCEMENT_URL = 'https://github.com/mui/mui-toolpad/discussions/1748';
export const DOCUMENTATION_INSTALLATION_URL =
  'https://mui.com/toolpad/getting-started/installation/';
export const DOCUMENTATION_QUICKSTART_URL = 'https://mui.com/toolpad/getting-started/quickstart/';
export const TOOLPAD_FUTURE_URL = 'https://mui.com/toolpad/getting-started/roadmap/';
export const ROADMAP_URL = 'https://github.com/orgs/mui/projects/9';
export const SCHEDULE_DEMO_URL = 'https://calendly.com/prakhar-mui/toolpad';

export const DEMO_DATASOURCES = new Set(['rest', 'function']);
export const PRODUCTION_DATASOURCES = new Set([
  'rest',
  'function',
  'googleSheets',
  'postgres',
  'mysql',
  'local',
]);

export const APP_ID_LOCAL_MARKER = '__LOCAL_MODE_APP_';

export const TOOLPAD_BRIDGE_GLOBAL = '__TOOLPAD_BRIDGE__';

export const NON_BINDABLE_CONTROL_TYPES = ['GridColumns'];
